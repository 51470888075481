
export interface HaciendaState{
    haciendas:Array<HaciendaItem>;
}
export interface HaciendaItem{
    haciendaId:number;
    descripcion:string;
    anioCultivo:number;
    ordenInterna:string;
    estado:boolean;
    zona:string;
    lotes:Array<Lote>;
}
export interface Lote{
    loteId:number;
    descripcion: string;
    hectareas: number;
    latitud:number;
    longitud:number
}
export class LoteModel implements Lote{
    loteId: number;
    descripcion: string;
    hectareas: number;
    latitud: number;
    longitud: number;
    constructor(){
        this.loteId=0;
        this.descripcion='';
        this.hectareas=0;
        this.latitud=0;
        this.longitud=0;
    }

}
export class HaciendaModel implements HaciendaItem{
    haciendaId: number;
    descripcion: string;
    anioCultivo: number;
    ordenInterna: string;
    estado: boolean;
    zona: string;
    lotes: Lote[];
    constructor(){
        this.haciendaId=0;
        this.descripcion='';
        this.anioCultivo=0;
        this.ordenInterna='';
        this.estado=true;
        this.zona='';
        this.lotes=new Array<Lote>()
    }
}